import React from 'react';
import "./Footer.css"
import { Link } from 'react-router-dom';
import logo from '../../assets/logowhite.png';
import Merchant from '../../assets/Merchant-Policy.docx';


function Footer() {
  return (
    <footer>
      <div className="footer wrapper d-flex  gap-20">
        
        <div className="menus d-flex grow-1 justify-between gap-10">
            <div className="aboutus w-4">
                <h4>About Us</h4>
                <div >
                   <div className="logo">
                   <img  src={logo} alt="logo" />   
                   </div>
                   <p className="gray">
                   Offer wide range of online payment modes to your customers such as credit cards, debit cards, 50+ netbanking options, UPI, EMI, BNPL, and wallets.                   </p>
                </div>
            </div>
          <div className="Important-links w-4">
            <h4>Important Links</h4>  
            <div>
                
              <Link to="/">Home</Link>
              <Link to="/about">About</Link>
              <Link to="/contact">Contact Us</Link>
             
              <Link to="/payment-gateway">Payment Gateway</Link>
            </div>
          </div>
         
          <div className="Featured w-4">
            <h4>Featured Services</h4>
            <div>
              <Link to='/web-checkout'>Web checkout</Link>
              
              <Link to='/recurring-payments'>Recurring Payments</Link>
              <Link to='/mobile-app-sdk'>Mobile App SDK</Link>
            </div>
          </div>
         

        </div>

      </div>
      <div className="footer-middle wrapper">
            <div className='d-flex justify-between'>
                <Link to="/terms-of-use"> Terms of Use</Link>
                <Link to="/privacy-policy "> Privacy Policy </Link>
                <Link to="/grievance-redressal"> Grievance Redressal</Link>
                <Link to="/cancellation-policy"> Cancellation Policy</Link>
                {/* <a href={Merchant} download="Merchant-Policy" title='Download Merchant Policy'>Merchant Policy</a> */}
            </div>

        </div>
      <div className="  footer-bottom">
        <div className="wrapper center">
        <p>Copyright © 2024, Paystream. All Rights Reserved.</p>
        </div>
       

      </div>
    </footer>
  );
}

export default Footer;
