import React from 'react';
import './Contact.css';
import Banner from '../../Componants/Banner/Banner';
import banner from "../../assets/banner.png";

const Contact = () => {

    const bannar = {
        banner: banner,
        h1: "Contact Us",
        button: "Let's Work Together!",
        buttonLink: "/contact",
        p: "You deserve to be seen. We are here to achieve your goal.",
      };
  return (
   <>
    <Banner banner={bannar} />
    <div className='contact-wrapper'>
      <div className="wrapper d-flex justify-between  gap-50">
        <div className="contactLeft">
            <div className="addressCard d-flex gap-20">
                <div className="icon self-center">
                <i class="fa-solid fa-location-dot"></i>
                </div>
                <div className="text">
                    <h3>Address</h3>
                    <p>UNIT NO 355, PLOT NO 4, 3RD FLOOR, COMMUNITY CENTER, SEC-14
                    ROHINI LANDMARK NEAR DDA COMMNUTY, DELHI, NORTH DELHI, DELHI, 110085</p>

                </div>

            </div>
            <div className="addressCard d-flex gap-20">
                <div className="icon self-center">
                <i class="fa-solid fa-envelope-circle-check"></i>
                </div>
                <div className="text">
                    <h3>Email</h3>
                    <p>info.raystreamtechpvtltd@gmail.com</p>

                </div>

            </div>
            <div className="addressCard d-flex gap-20">
                <div className="icon self-center">
                <i class="fa-solid fa-phone"></i>
                </div>
                <div className="text">
                    <h3>Phone</h3>
                    <p>+91-8859068550</p>

                </div>

            </div>

        </div>
       
     
        <div className="map-wrapper" style={{width:"100%"}}>
        <div className="wrapper">
        <iframe src="https://www.google.com/maps/embed?pb=!1m18!1m12!1m3!1d3499.252841920429!2d77.12555597533861!3d28.711988875621454!2m3!1f0!2f0!3f0!3m2!1i1024!2i768!4f13.1!3m3!1m2!1s0x390d01e8d2130a31%3A0x91d670d4b87ca2d9!2sRohini%20DDA%20sports%20complex%20parking!5e0!3m2!1sen!2sin!4v1720785966258!5m2!1sen!2sin" width="100%" height="100" style={{border:"0"}} allowfullscreen="" loading="lazy" referrerpolicy="no-referrer-when-downgrade"></iframe>
        </div>
      </div>
      </div>
     
      </div>
      
   
   </>
  );
}

export default Contact;
