import React from 'react'
import Banner from '../../Componants/Banner/Banner';
import "../PrivacyPolicies/PrivacyPolicies.css";
import banner from "../../assets/pri-banner.jpg";

function PrivacyPolicies() {
    const bannar = {
        banner: banner,
        h1: "Privacy Policy",
    };
  return (
    <div>
       <Banner banner={bannar}></Banner>
       <div className='main'>
        <div className='wrapper'>
            <div className='policy-container'>
                <div className='policy-header f-color f-16'>
                    <h1>Privacy Policy For PayStream</h1>
                </div>
                <div className='policy-main-text f-color f-16'>
                    <p>At PayStream, accessible from https://PayStream.com, one of our main priorities is the privacy of our visitors. This Privacy Policy document contains types of information that is collected and recorded by PayStream and how we use it. If you have additional questions or require more information about our Privacy Policy, do not hesitate to contact us through email at nodal@PayStream.com</p>
                </div>
            </div>
            <div className='policy-container'>
                <div className='policy-header f-color f-16'>
                    <h1>Log Files</h1>
                </div>
                <div className='policy-main-text f-color f-16'>
                    <p>PayStream follows a standard procedure of using log files. These files log visitors when they visit websites. All hosting companies do this and a part of hosting services’ analytics. The information collected by log files include internet protocol (IP) addresses, browser type, Internet Service Provider (ISP), date and time stamp, referring/exit pages, and possibly the number of clicks. These are not linked to any information that is personally identifiable. The purpose of the information is for analyzing trends, administering the site, tracking users’ movement on the website, and gathering demographic information.</p>
                </div>
            </div>
            <div className='policy-container'>
                <div className='policy-header f-color f-16'>
                    <h1>Account Information</h1>
                </div>
                <div className='policy-main-text f-color f-16'>
                    <p>In the off chance that you make a record to exploit the full scope of administrations offered on PayStream, we request and record Personal Information, for example, your name, email address, and mobile number. We may gather and store your Sensitive Personal Data or Information, (for example, any monetary data credit/debit card subtleties, financial balance and know your client (“KYC “) archives according to RBI guidelines and some other data as might be appropriate) that the User may select to spare in the User account made with PayStream). We utilize your email address to send you updates, news etc. If you do not want to receive any sms/mail from us regarding use of our services, please click on the unsubscribe link provided at the bottom of such e-mails. We use your mobile numbers to send you transaction alerts and SMS alerts based on your preferences. If you do not wish to receive such SMSes from us, please raise a ticket at support desk to stop receiving SMSes from us. PayStream assures that your Personal Information will not be made public or sold to any third party. We also store customer information of customers making payments through PayStream. However, only when user chooses to share the info on the businesses linked with PayStream we share the information to respective businesses. However, PayStreamis not liable in any way for any misuse of this information by the business or people related to the businesses to whom the information is shared by the customer.</p>
                </div>
            </div>
            <div className='policy-container'>
                <div className='policy-header f-color f-16'>
                    <h1>Cookies And Web Beacons</h1>
                </div>
                <div className='policy-main-text f-color f-16'>
                    <p>Like any other website, PayStream uses ‘cookies’. These cookies are used to store information including visitors’ preferences, and the pages on the website that the visitor accessed or visited. The information is used to optimize the users’ experience by customizing our web page content based on visitors’ browser type and/or other information.</p>
                </div>
            </div>
            <div className='policy-container'>
                <div className='policy-header f-color f-16'>
                    <h1>Google DoubleClick DART Cookie</h1>
                </div>
                <div className='policy-main-text f-color f-16'>
                    <p>Google is one of a third-party vendor on our site. It also uses cookies, known as DART cookies, to serve ads to our site visitors based upon their visit to www.website.com and other sites on the internet. However, visitors may choose to decline the use of DART cookies by visiting the Google ad and content network Privacy Policy at the following URL – https://policies.google.com/technologies/ads Our Advertising Partners Some of advertisers on our site may use cookies and web beacons. Our advertising partners are listed below. Each of our advertising partners has their own Privacy Policy for their policies on user data. For easier access, we hyperlinked to their Privacy Policies below. Google https://policies.google.com/technologies/ads</p>
                </div>
            </div>
            <div className='policy-container'>
                <div className='policy-header f-color f-16'>
                    <h1>Privacy Policies</h1>
                </div>
                <div className='policy-main-text f-color f-16'>
                    <p>You may consult this list to find the Privacy Policy for each of the advertising partners of PayStream. Our Privacy Policy was created with the help of the GDPR Privacy Policy Generator and the Privacy Policy Generator from TermsFeedplus the Terms and Conditions Template Third-party ad servers or ad networks uses technologies like cookies, JavaScript, or Web Beacons that are used in their respective advertisements and links that appear on PayStream, which are sent directly to users’ browser. They automatically receive your IP address when this occurs. These technologies are used to measure the effectiveness of their advertising campaigns and/or to personalize the advertising content that you see on websites that you visit. Note that PayStreamhas no access to or control over these cookies that are used by third-party advertisers.</p>
                </div>
            </div>
            <div className='policy-container'>
                <div className='policy-header f-color f-16'>
                    <h1>Third Party Privacy Policies</h1>
                </div>
                <div className='policy-main-text f-color f-16'>
                    <p>PayStream’s Privacy Policy does not apply to other advertisers or websites. Thus, we are advising you to consult the respective Privacy Policies of these third-party ad servers for more detailed information. It may include their practices and instructions about how to opt-out of certain options. You may find a complete list of these Privacy Policies and their links here: Privacy Policy Links. You can choose to disable cookies through your individual browser options. To know more detailed information about cookie management with specific web browsers, it can be found at the browsers’ respective websites. What Are Cookies?</p>
                </div>
            </div>
            <div className='policy-container'>
                <div className='policy-header f-color f-16'>
                    <h1>Children's Information</h1>
                </div>
                <div className='policy-main-text f-color f-16'>
                    <p>Another part of our priority is adding protection for children while using the internet. We encourage parents and guardians to observe, participate in, and/or monitor and guide their online activity. PayStream does not knowingly collect any Personal Identifiable Information from children under the age of 13. If you think that your child provided this kind of information on our website, we strongly encourage you to contact us immediately and we will do our best efforts to promptly remove such information from our records.</p>
                </div>
            </div>
            <div className='policy-container'>
                <div className='policy-header f-color f-16'>
                    <h1>Online Privacy Policy Only</h1>
                </div>
                <div className='policy-main-text f-color f-16'>
                    <p>This Privacy Policy applies only to our online activities and is valid for visitors to our website with regards to the information that they shared and/or collect in PayStream. This policy is not applicable to any information collected offline or via channels other than this website.</p>
                </div>
            </div>
            <div className='policy-container'>
                <div className='policy-header f-color f-16'>
                    <h1>Consent</h1>
                </div>
                <div className='policy-main-text f-color f-16'>
                    <p>By using our website, you hereby consent to our Privacy Policy and agree to its Terms and Conditions.</p>
                </div>
            </div>
            <div className='policy-container'>
                <div className='policy-header f-color f-16'>
                    <h1>Complaints and Grievance Redressal</h1>
                </div>
                <div className='policy-main-text f-color f-16'>
                    <p>Any complaints with respect to Personal Information or content of this Website or comment or breach of use of the Website or any intellectual property of any User shall be informed to the designated Grievance Officer on below in writing or through email. GRIEVANCE OFFICER: WEBSITE: PayStream.in ADDRESS:

<br/><br/>UNIT NO 355, PLOT NO 4, 3RD FLOOR, COMMUNITY CENTER, SEC-14
ROHINI LANDMARK NEAR DDA COMMNUTY, DELHI, NORTH DELHI, DELHI, 110085 PH:+91-8859068550</p>
                </div>
            </div>
        </div>
       </div>
    </div>
  )
}

export default PrivacyPolicies
