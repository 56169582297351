import React from 'react'

function ForgotPassword() {
  return (
    <div className="forgot-password-container">
      <h2>Password Reset</h2>
      <div className='forgot-label'>To reset your password, please enter your email address or username below.</div>
      <form>
        <label htmlFor="email">Email / UserName:</label>
        <input
          type="text"
          placeholder="Enter your username oremail"
          required
        />
        <button type="submit" className='btn-1'>Reset Password</button>
      </form>
    </div>
  )
}

export default ForgotPassword
