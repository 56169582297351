import React from "react";
import Banner from "../../Componants/Banner/Banner";
import "../Terms&Condition/TermsandCondition.css";
import banner from "../../assets/banner.png";

function TermsandCondition() {
  const bannar = {
    banner: banner,
    h1: "Terms & Conditions",
  };

  return (
    <div>
      <Banner banner={bannar}></Banner>
      <div className="main">
        <div className="wrapper">
          <div className="terms-condition-content">
            <p className="f-color f-20 fw-400">
              The terms and conditions contained hereinafter (“TERMS OF USE”)
              shall apply to the use of the Website https://www.paystream.com/
              and any other linked pages, products, software(s), API keys,
              features, content or Application services (including but without
              limitation to any mobile application services) in connection
              therewith, offered from time to time by PayStream (“PayStream”
              or “WE” or “OUR” or “US”) (collectively called as “WEBSITE”). Any
              person (“USER” or “CLIENT”) entering into the Website has to read
              the Terms and Conditions (“TERMS OF USE” and “PRIVACY POLICIES”)
              an d has to accept these terms and conditions set out
              unconditionally and irrevocably. These Terms of Use and Privacy
              Policies enforce this binding agreement on all the users of
              PayStream. The User should understand and irrevocably agree that
              even if he is allowed to execute transactions on our payment
              platform, the funds wouldn’t be deposited into the account of such
              User until the KYC obligations are pending. The User has to
              complete RBI’s KYC obligations in accordance with the RBI’s KYC
              Guidelines. However, of the RBI’s KYC guidelines are not complied
              with by the User, PayStream reserves the right to stop the
              release of the settled amount to the User. These amounts might be
              reversed to the original account from where the payment was made.
              The Terms of Use have been set out below. Please read it
              thoroughly before agreeing to them. If, at any point, you do not
              agree to these Terms of Use, you can immediately revoke the usage
              of this Website. The acceptance of the Terms of Use can be done
              by:
            </p>
          </div>
          <div className="terms-condition-content">
            <ul>
              <li>
                Clicking on the “Accept” or “Agree” option provided by
                PayStream in the user interface after reading the Terms of
                Use.
              </li>
              <li>
                By the usage of the services. When you start using the services,
                it is assumed that you understand and agree that your usage of
                services will be treated by PayStream as the acceptance of the
                Terms of Use.
              </li>
            </ul>
          </div>
          <div className="terms-condition-content">
            <p className="f-color f-20 fw-400">
              Under this Terms of Use, a user is a natural or legal person who
              agrees to the set Terms and Conditions for himself or on behalf of
              someone else.It is stated that the Privacy Policy (which is given
              separately) is an essential part of such Terms of Use and must be
              interpreted in conjunction with them. If any of the provisions
              from this Terms of Use become illegal or unenforceable, it will
              not affect other Terms of Use mentioned hereunder. To prevent
              ambiguity, if any of the clauses are invalid or unenforceable, the
              remainder of the following terms and Conditions of Use will be
              obligatory on the Consumer. We reserve the right, at our absolute
              discretion, to amend or change the Terms of Use at any point of
              time without prior warning to the Users. All the modified Terms of
              Use will be updated here and will have an immediate effect. By
              proceeding to use the services in this Website after modifications
              have been rendered, you expressly consent to the revised/ updated
              Terms of Use, which shall overrule any such terms of use that was
              initially agreed by the User. You must be aware and adhere to all
              the relevant rules and regulations in your jurisdiction, and you
              will be entirely responsible for any unawareness.
            </p>
          </div>
        </div>

        <div className="terms-condition-main-box">
          <div className="condition-content-head">
            <div className="wrapper">
              <div className="condition-content-heading ">
                <h1 className="f-color f-30 fw-400">Definitions</h1>
                <p className="f-color f-16">
                  The Definitions under the Terms of Use include:
                </p>
              </div>

              <div className="term-card-icon">
                <div className="term-card-icon-column">
                  <div className="term-card-icon-column1">
                    <h2>Payee</h2>
                    <p>
                      A Payee is someone to whom a payment is made or who
                      receives the payment.
                    </p>
                  </div>
                  <div className="term-card-icon-column1">
                    <h2 style={{ color: "#1343BE" }}>Payer</h2>
                    <p>A payer is a person who makes the payment.</p>
                  </div>
                  <div className="term-card-icon-column2">
                    <h2>User</h2>
                    <p>
                      Any person who has registered in PayStreamto use the
                      Website or the Application or avail of its services can be
                      called a User. A User can make or receive payments to or
                      from third parties. A person who has been authorized to
                      avail of services on anyone else’s behalf is also covered
                      under the term “User”.
                    </p>
                  </div>
                  <div className="term-card-icon-column3">
                    <h2>Products</h2>
                    <p>
                      Under this Terms of Use, products include any service or
                      product which is purchases or offered for sale by the User
                      to third parties using these services
                    </p>
                  </div>
                  <div className="term-card-icon-column3">
                    <h2 style={{ color: "#A9570" }}>Transaction</h2>
                    <p>
                      A Transaction includes the process of sending money from a
                      user to a payee or a payer to a user.
                    </p>
                  </div>
                  
                  <div className="term-card-icon-column4">
                    <h2 style={{ color: "#A41C77" }}>Transaction Amount</h2>
                    <p>
                      The Transaction amount is an amount payable to a user or
                      payee and receivable by any user or payee.
                    </p>
                  </div>
                  <div className="term-card-icon-column1">
                    <h2 style={{ color: "#573660" }}>Eligibility</h2>
                    <p>
                      This Website and its services are solely made for persons
                      who are capable of contracting under the Indian Contract
                      Act and who are above the age of 18. Anyone below 18 years
                      old is expressly prohibited from using the websites and
                      their services.
                    </p>
                  </div>
                  <div className="term-card-icon-column2">
                    <h2 style={{ color: "##EB5726" }}>Use of Services</h2>
                    <p>
                      The services of PayStream can be used to make payments
                      or receive payments.
                    </p>
                  </div>

                  <div className="term-card-icon-column1">
                    <h2 style={{ color: "#0EBF24" }}>Privacy Policy</h2>
                    <p>
                      The User understands and acknowledges that his personal
                      information, which is subject to this Website’s privacy
                      policy, may be used and stored in our database. Please
                      refer to our Privacy Policy for a piece of detailed
                      information.
                    </p>
                  </div>
                  <div className="term-card-icon-column1">
                    <h2 style={{ color: "#647171" }}>Security</h2>
                    <p>
                      As specified continuously by PayStream, the User should
                      not hold PayStream liable for any unauthorized,
                      fraudulent, erroneous or duplicate use of his access to
                      the Website.
                    </p>
                  </div>
                  <div className="term-card-icon-column4">
                    <h2 style={{ color: "#E84863" }}>Services</h2>
                    <p>
                      The Services provided by PayStream and service providers
                      to its users. Under these services, a user can make
                      payments to other payees or receive payments from other
                      payers. This can be done through RTGS/NEFT/IMPS or
                      credit/debit cards or internet banking, or any other
                      payment modes made available by the banks.
                    </p>
                  </div>
                  <div className="term-card-icon-column3">
                  <h2 style={{ color: "#EA8629" }}>Service Provider</h2>
                  <p>
                    A Service Provider is a bank or an association, or a
                    facility provider whose services are being used.
                  </p>
                </div>
                <div className="term-card-icon-column3">
                  <h2 style={{ color: "#17BE76" }}>Limitation of Liability</h2>
                  <p>
                    The User understands and agrees that the entire risk with
                    respect to his usage and access of the Website and its
                    services remains with him. Neither PayStream nor any other
                    party involved can be held liable for any incidental,
                    consequential or exemplary damages.
                  </p>
                </div>
                <div className="term-card-icon-column2">
                  <h2 style={{ color: "##F11E21" }}>Every User</h2>
                  <p className="mb-20">
                    Every User registered under this Website is responsible for:
                  </p>
                  <p>Providing correct information best to his knowledge.</p>
                  <p>
                    Providing an original copy of proof of delivery of products
                    on demand.{" "}
                  </p>
                  <p>
                    Ensuring that his licenses and registrations are valid and
                    legal to carry on his business.
                  </p>
                </div>
                <div className="term-card-icon-column2">
                    <h2 style={{ color: "#198C85" }}>Registration for Services</h2>
                    <p>
                    To avail the services of the Website, a user has to sign up or create an account with all the necessary documents. When a user registers for PayStream, he agrees to provide the correct information as per the best of his knowledge. Failing to provide such correct information will lead to PayStream cancelling the registration of such users. It is pertinent to note that every User shall take responsibility for anything that happens from his user account.
                    </p>
                  </div>
                </div>
               
              </div>
            </div>

            {/* <div className='term-card-icon'>

                                <div className='term-card-icon-column'>
                                    <div className='term-card-icon-column3'>
                                            <h2>Products</h2>
                                            <p>Under this Terms of Use, products include any service or product which is purchases or offered for sale by the User to third parties using these services</p>                                       
                                    </div>
                                    <div className='term-card-icon-column3'>
                                            <h2>Transaction</h2>
                                            <p>A Transaction includes the process of sending money from a user to a payee or a payer to a user.</p>
                                       
                                    </div>
                                    <div className='term-card-icon-column4'>
                                    <h2>Transaction Amount</h2>
                                            <p>The Transaction amount is an amount payable to a user or payee and receivable by any user or payee.</p>
                                    </div>
                                </div>

                            </div> */}
          </div>
        </div>
      </div>
    </div>
  );
}

export default TermsandCondition;
