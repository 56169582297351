import React from 'react'
import Banner from '../../Componants/Banner/Banner';
import "../PaymentRecurring/PaymentRecurring.css";
import PaymentRecurring1 from "../../assets/payment-recurring1.jpg";
import banner from "../../assets/recurring-banner.jpg";

function PaymentRecurring() {
    const bannar = {
        banner: banner,
        h1: "Automate Payment Collection With",
        h2: "Recurring Payments",
        // button: "Get Start!",
        // buttonLink: "/contact",
        p: "Offer multiple payment methods to enable recurring payments. Manage subscriptions for your business.",
    };
    return (
        <div>
            <Banner banner={bannar}></Banner>
            <div className='main'>
                  
                   <div className='payment-recurring'>
                   <div className='wrapper'>
                    <div className='payment-recurring-main'>
                        <div className='payment-recurring-left '>
                            <img src={PaymentRecurring1}></img>
                        </div>
                        <div className='payment-recurring-right center '>
                            <div className='wrapper'>
                            <h1 className='f-color f-40'>Why PayStream Recurring Payments Suite ?</h1>
                            <div className='payment-recurring-content'>
                                <ul className='l-height f-color fw-600 f-21'>
                                    <li>Offer multiple payment methods</li>
                                    <li>Create custom subscription plans</li>
                                    <li>Compliant with the latest guideline</li>
                                    <li>Analytics & Reportings</li>
                                </ul>
                            </div>
                            </div>
                            
                        </div>
                    </div>
                    </div>
                   </div>
                    <div className='payment-steps'>
                        <div className='wrapper'>
                        <div className='payment-step-heading f-color f-32 fw-600'>
                            <h2>Activate PayStream Recurring <br />Payments Suite In 3 Simple Steps</h2>
                        </div>
                        <div className='payment-steps-icon'>
                            <div className='payment-steps-icon-column'>
                                <div className='payment-steps-wrapper payment-steps-wrapper-populated'>
                                    <div className='payment-steps-icon-label'>
                                        <h1 className='f-color fw-600 f-32'>STEP1</h1>
                                    </div>
                                    <div className='payment-steps-icon-container'>
                                        <div className="image-circle">
                                        <i class="fa-solid fa-right-to-bracket icon-size icon-color"></i>
                                        </div>
                                        <div className="image-circle-text">
                                            <h3 className='f-25'>Sign Up On PayStream</h3>
                                            <p> Complete your profile and link your bank account to start collecting payments</p>
                                        </div>

                                    </div>

                                </div>

                            </div>
                            <div className='payment-steps-icon-column'>
                            <div className='payment-steps-wrapper payment-steps-wrapper-populated'>
                                    <div className='payment-steps-icon-label'>
                                        <h1 className='f-color fw-600 f-25'>STEP2</h1>
                                    </div>
                                    <div className='payment-steps-icon-container'>
                                        <div className="image-circle">
                                        <i class="fa-sharp fa-solid fa-share-nodes icon-size icon-color"></i>
                                        </div>
                                        <div className="image-circle-text">
                                            <h3 className='f-25'>Enable PayStream Payment Gateway</h3>
                                            <p> Integrate your business with PayStream payment gateway and start collecting payments</p>
                                        </div>

                                    </div>

                                </div>
                            </div>
                            <div className='payment-steps-icon-column'>
                            <div className='payment-steps-wrapper payment-steps-wrapper-populated'>
                                    <div className='payment-steps-icon-label'>
                                        <h1 className='f-color fw-600 f-32'>STEP3</h1>
                                    </div>
                                    <div className='payment-steps-icon-container'>
                                        <div className="image-circle">
                                            
                                        <i class="fa-solid fa-envelopes-bulk icon-size icon-color"></i>
                                            
                                            
                                        </div>
                                        <div className="image-circle-text">
                                            <h3 className='f-25'>Request For Recurring Payments</h3>
                                            <p>Email us at support@PayStream.com or contact your Account Manager</p>
                                        </div>

                                    </div>

                                </div>
                            </div>

                        </div>
                        </div>
                        
                    </div>
                </div>
        </div>
    )
}

export default PaymentRecurring
