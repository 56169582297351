import React from 'react'

function Register() {
  return (
    <div className="registration-form-container">
    <form className="login-form">
    <h2>Register</h2>
    <div className="form-group">
        <label htmlFor="username">User Name</label>
        <input type="text" name="userName" placeholder="enter user name" />
      </div>
      <div className="form-group">
        <label htmlFor="firstName">First Name</label>
        <input type="text" name="firstName" placeholder="enter first name" />
      </div>
      <div className="form-group">
        <label htmlFor="lastName">Last Name</label>
        <input type="text" name="lastName" placeholder="enter last name" />
      </div>
      <div className="form-group">
        <label htmlFor="email">Email</label>
        <input type="email" name="email" placeholder="email" />
      </div>
      <div className="form-group">
        <label htmlFor="password">Password</label>
        <input type="password" name="password" placeholder="enter password" />
      </div>
      <div className="form-group">
        <label htmlFor="password">Confirm Password</label>
        <input type="password" name="confirmpassword" placeholder="enter confirm password" />
      </div>
      <div className='form-group registration-btn'>
      <button type="submit" className='btn-1'>Register</button>
      </div>
    
    </form>
  </div>
  )
}

export default Register
