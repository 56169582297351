import React from 'react'
import "../LoginRegister/style/style.css"
import { useNavigate } from 'react-router-dom'

function Login() {
    const navigate=useNavigate()
  return (
    <div className="login-container">
    <form className="login-form">
      <h2>Login</h2>
      <div className="form-group">
        <label htmlFor="username">Username</label>
        <input type="text" name="username" placeholder='enter username' />
      </div>
      <div className="form-group">
        <label htmlFor="password">Password</label>
        <input type="password" name="password" placeholder='enter password' />
      </div>
      <div className="form-actions">
        <button type="submit" className="login-button btn-1">Login</button>
        <button type="button" className="register-button btn-1"
        onClick={()=>{ 
            navigate("/register")} }>Register</button>
      </div>
      
        <div className='form-span'>
        <span
        onClick={()=>{ 
          navigate("/forgotpassword")} }>forgot password ?</span>
        </div>
    </form>
  </div>
  )
}

export default Login
